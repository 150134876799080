import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import AuthLayout from '@/views/Pages/AuthLayout.vue';
import AuthLayoutV2 from '@/views/Pages/AuthLayoutV2.vue';
// GeneralViews
import NotFound from '@/views/GeneralViews/NotFoundPage.vue';

// Calendar
const Calendar = () => import(/* webpackChunkName: "extra" */ '@/views/Calendar/Calendar.vue');
// Charts
const Charts = () => import(/* webpackChunkName: "dashboard" */ '@/views/Charts.vue');

// Components pages
const Buttons = () => import(/* webpackChunkName: "components" */ '@/views/Components/Buttons.vue');
const Cards = () => import(/* webpackChunkName: "components" */ '@/views/Components/Cards.vue');
const GridSystem = () => import(/* webpackChunkName: "components" */ '@/views/Components/GridSystem.vue');
const Notifications = () => import(/* webpackChunkName: "components" */ '@/views/Components/Notifications.vue');
const Icons = () => import(/* webpackChunkName: "components" */ '@/views/Components/Icons.vue');
const Typography = () => import(/* webpackChunkName: "components" */ '@/views/Components/Typography.vue');

// Dashboard pages
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/Dashboard.vue');
const AlternativeDashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/AlternativeDashboard.vue');
const Widgets = () => import(/* webpackChunkName: "dashboard" */ '@/views/Widgets.vue');

// Forms pages
const FormElements = () => import(/* webpackChunkName: "forms" */ '@/views/Forms/FormElements.vue');
const FormComponents = () => import(/* webpackChunkName: "forms" */ '@/views/Forms/FormComponents.vue');
const FormValidation = () => import(/* webpackChunkName: "forms" */ '@/views/Forms/FormValidation.vue');

// Maps pages
const GoogleMaps = () => import(/* webpackChunkName: "extra" */ '@/views/Maps/GoogleMaps.vue');
const VectorMaps = () => import(/* webpackChunkName: "extra" */ '@/views/Maps/VectorMaps.vue');

// Pages
const User = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/UserProfile.vue');
const Pricing = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Pricing.vue');
const TimeLine = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/TimeLinePage.vue');
const Login = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Login.vue');
const Home = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Home.vue');
const Register = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Register.vue');
const Lock = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Lock.vue');

// TableList pages
const RegularTables = () => import(/* webpackChunkName: "tables" */ '@/views/Tables/RegularTables.vue');
const SortableTables = () => import(/* webpackChunkName: "tables" */ '@/views/Tables/SortableTables.vue');
const PaginatedTables = () => import(/* webpackChunkName: "tables" */ '@/views/Tables/PaginatedTables.vue');

// Setup pages
const Users = () => import(/* webpackChunkName: "tables" */ '@/views/Setup/UsersFolder/Users.vue');
const AddUsers = () => import(/* webpackChunkName: "tables" */ '@/views/Setup/UsersFolder/AddUsers.vue');
const EditUsers = () => import(/* webpackChunkName: "tables" */ '@/views/Setup/UsersFolder/EditUsers.vue');
const MyUserProfile = () => import(/* webpackChunkName: "tables" */ '@/views/Setup/UsersFolder/UserProfile/MyUserProfile.vue');

const Subsidiaries = () => import(/* webpackChunkName: "tables" */ '@/views/Setup/SubsidiariesFolder/Subsidiaries.vue');
const AddSubsidiaries = () => import(/* webpackChunkName: "tables" */ '@/views/Setup/SubsidiariesFolder/AddSubsidiaries.vue');
const EditSubsidiaries = () => import(/* webpackChunkName: "tables" */ '@/views/Setup/SubsidiariesFolder/EditSubsidiaries.vue');
const DocumentType = () => import(/* webpackChunkName: "tables" */ '@/views/Setup/DocumentFolder/DocumentType.vue');
const AddDocumentType = () => import(/* webpackChunkName: "tables" */ '@/views/Setup/DocumentFolder/AddDocumentType.vue');
const EditDocumentType = () => import(/* webpackChunkName: "tables" */ '@/views/Setup/DocumentFolder/EditDocumentType.vue');
const ApplicationType = () => import(/* webpackChunkName: "tables" */ '@/views/Setup/ApplicationFolder/ApplicationType.vue');
const AddApplicationType = () => import(/* webpackChunkName: "tables" */ '@/views/Setup/ApplicationFolder/AddApplicationType.vue');
const EditApplicationType = () => import(/* webpackChunkName: "tables" */ '@/views/Setup/ApplicationFolder/EditApplicationType.vue');

// Document
const Groups = () => import(/* webpackChunkName: "tables" */ '@/views/Group/Groups.vue');
const AddGroups = () => import(/* webpackChunkName: "tables" */ '@/views/Group/AddGroups.vue');
const SettingGroups = () => import(/* webpackChunkName: "tables" */ '@/views/Group/SettingGroups.vue');
const AddGroupAppSetting = () => import(/* webpackChunkName: "tables" */ '@/views/Group/AddGroupAppSetting.vue');
const AddGroupDocSetting = () => import(/* webpackChunkName: "tables" */ '@/views/Group/AddGroupDocSetting.vue');
const MemberGroups = () => import(/* webpackChunkName: "tables" */ '@/views/Group/MemberGroups.vue');
const AddMemberGroups = () => import(/* webpackChunkName: "tables" */ '@/views/Group/AddMemberGroups.vue');
const DocGroups = () => import(/* webpackChunkName: "tables" */ '@/views/Group/DocGroups.vue');
const EditGroups = () => import(/* webpackChunkName: "tables" */ '@/views/Group/EditGroups.vue');
// Document
const Documents = () => import(/* webpackChunkName: "tables" */ '@/views/Documents/Documents.vue');
const AddDocuments = () => import(/* webpackChunkName: "tables" */ '@/views/Documents/AddDocuments.vue');
const EditDocuments = () => import(/* webpackChunkName: "tables" */ '@/views/Documents/EditDocuments.vue');
const ViewDocuments = () => import(/* webpackChunkName: "tables" */ '@/views/Documents/ViewDocuments.vue');


let componentsMenu = {
  path: '/components',
  component: DashboardLayout,
  redirect: '/components/buttons',
  name: 'Components',
  children: [
    {
      path: 'buttons',
      name: 'Buttons',
      component: Buttons
    },
    {
      path: 'cards',
      name: 'Cards',
      component: Cards
    },
    {
      path: 'grid-system',
      name: 'Grid System',
      component: GridSystem
    },
    {
      path: 'notifications',
      name: 'Notifications',
      component: Notifications
    },
    {
      path: 'icons',
      name: 'Icons',
      component: Icons
    },
    {
      path: 'typography',
      name: 'Typography',
      component: Typography
    }
  ]
};

let formsMenu = {
  path: '/forms',
  component: DashboardLayout,
  redirect: '/forms/elements',
  name: 'Forms',
  children: [
    {
      path: 'elements',
      name: 'Form elements',
      component:  FormElements
    },
    {
      path: 'components',
      name: 'Form components',
      component:  FormComponents
    },
    {
      path: 'validation',
      name: 'Form validation',
      component:  FormValidation
    }
  ]
};

let tablesMenu = {
  path: '/tables',
  component: DashboardLayout,
  redirect: '/table/regular',
  name: 'Tables menu',
  children: [
    {
      path: 'regular',
      name: 'Tables',
      component: RegularTables
    },
    {
      path: 'sortable',
      name: 'Sortable',
      component: SortableTables
    },
    {
      path: 'paginated',
      name: 'Paginated Tables',
      component: PaginatedTables
    }
  ]
};

let mapsMenu = {
  path: '/maps',
  component: DashboardLayout,
  name: 'Maps',
  redirect: '/maps/google',
  children: [
    {
      path: 'google',
      name: 'Google Maps',
      component: GoogleMaps
    },
    {
      path: 'vector',
      name: 'Vector Map',
      component: VectorMaps
    }
  ]
};

let pagesMenu = {
  path: '/pages',
  component: DashboardLayout,
  name: 'Pages',
  redirect: '/pages/user',
  children: [
    {
      path: 'user',
      name: 'User Page',
      component: User
    },
    {
      path: 'timeline',
      name: 'Timeline Page',
      component: TimeLine
    }
  ]
};

let authPages = {
  path: '/',
  // component: AuthLayout,
  component: AuthLayoutV2,
  name: 'Authentication',
  children: [
    {
      path: '/home',
      name: 'Home',
      component: Home,
      meta: {
        noBodyBackground: true
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/register',
      name: 'Register',
      component: Register
    },
    {
      path: '/pricing',
      name: 'Pricing',
      component: Pricing
    },
    {
      path: '/lock',
      name: 'Lock',
      component: Lock
    },
    { path: '*', component: NotFound }
  ]
};

let setupMenu = {
  path: '/setup',
  component: DashboardLayout,
  redirect: '/setup/users',
  name: 'Setup Menu',
  children: [
    {
      path: 'users',
      name: 'Users',
      meta : { requiresAuth: true },
      component: Users,
    },
    {
      path: 'addUsers',
      name: 'Add Users',
      meta : { requiresAuth: true },
      component: AddUsers,
    },
    {
      path: 'editUsers/:id',
      name: 'Edit Users',
      meta : { requiresAuth: true },
      component: EditUsers,
    },
    {
      path: 'my_profile',
      name: 'My Profile',
      meta : { requiresAuth: true },
      component: MyUserProfile,
    },
    {
      path: 'subsidiaries',
      name: 'Subsidiaries',
      meta : { requiresAuth: true },
      component: Subsidiaries,
    },
    {
      path: 'addSubsidiaries',
      name: 'Add Subsidiaries',
      meta : { requiresAuth: true },
      component: AddSubsidiaries,
    },
    {
      path: 'editSubsidiaries/:id',
      name: 'Edit Subsidiaries',
      meta : { requiresAuth: true },
      component: EditSubsidiaries,
    },
    {
      path: 'document_type',
      name: 'Document Type',
      meta : { requiresAuth: true },
      component: DocumentType
    },
    {
      path: 'addDocument_type',
      name: 'Add Document Type',
      meta : { requiresAuth: true },
      component: AddDocumentType,
    },
    {
      path: 'editDocument_type/:id',
      name: 'Edit Document Type',
      meta : { requiresAuth: true },
      component: EditDocumentType,
    },
    {
      path: 'application_type',
      name: 'Application Type',
      meta : { requiresAuth: true },
      component: ApplicationType
    },
    {
      path: 'addApplication_type',
      name: 'Add Application Type',
      meta : { requiresAuth: true },
      component: AddApplicationType,
    },
    {
      path: 'editApplication_type/:id',
      name: 'Edit Application Type',
      meta : { requiresAuth: true },
      component: EditApplicationType,
    }
  ]
};

let docMenu = {
  path: '/doc',
  component: DashboardLayout,
  redirect: '/doc/documents',
  name: 'Document Menu',
  children: [
    {
      path: 'documents',
      name: 'Documents',
      meta : { requiresAuth: true },
      component: Documents
    },
    {
      path: 'addDocuments',
      name: 'Add Documents',
      meta : { requiresAuth: true },
      component: AddDocuments
    },
    {
      path: 'editDocuments/:id',
      name: 'Edit Documents',
      meta : { requiresAuth: true },
      component: EditDocuments
    },
    {
      path: 'viewDocuments/:id',
      name: 'View Documents',
      meta : { requiresAuth: true },
      component: ViewDocuments
    },
  ]
};

let groupMenu = {
  path: '/group',
  component: DashboardLayout,
  redirect: '/group/groups',
  name: 'Group Menu',
  children: [
    {
      path: 'groups',
      name: 'Groups',
      meta : { requiresAuth: true },
      component: Groups
    },
    {
      path: 'editGroups/:id',
      name: 'Edit Groups',
      meta : { requiresAuth: true },
      component: EditGroups,
    },
    {
      path: 'addGroups',
      name: 'Add Groups',
      meta : { requiresAuth: true },
      component: AddGroups,
    },
    {
      path: 'settingGroup/:id',
      name: 'Group Setting',
      meta : { requiresAuth: true },
      component: SettingGroups,
    },
    {
      path: 'addGroupAppSetting/:id',
      name: 'Add Group App Setting',
      meta : { requiresAuth: true },
      component: AddGroupAppSetting,
    },
    {
      path: 'addGroupDocSetting/:id',
      name: 'Add Groups Doc Setting',
      meta : { requiresAuth: true },
      component: AddGroupDocSetting,
    },
    {
      path: 'memberGroup/:id',
      name: 'Group Member',
      meta : { requiresAuth: true },
      component: MemberGroups,
    },
    {
      path: 'addMemberGroups/:id',
      name: 'Add Group Members',
      meta : { requiresAuth: true },
      component: AddMemberGroups,
    },
    {
      path: 'docGroup/:id',
      name: 'Group Doc',
      meta : { requiresAuth: true },
      component: DocGroups,
    },
  ]
};


const routes = [
  {
    path: '/',
    redirect: '/login',
    name: 'Home'
  },
  // componentsMenu,
  // formsMenu,
  // tablesMenu,
  // mapsMenu,
  // pagesMenu,
  setupMenu,
  docMenu,
  groupMenu,
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/dashboard',
    name: 'Dashboard layout',
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard
      },
      {
        path: 'alternative',
        name: 'Alternative',
        component: AlternativeDashboard,
        meta: {
          navbarType: 'light'
        }
      },
      {
        path: 'calendar',
        name: 'Calendar',
        component: Calendar
      },
      {
        path: 'charts',
        name: 'Charts',
        component: Charts
      },
      {
        path: 'widgets',
        name: 'Widgets',
        component: Widgets
      }
    ]
  },
  authPages,
];

export default routes;
