var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',[_c('template',{slot:"links"},[(_vm.loginUser.role_id == 1 || _vm.loginUser.role_id == 2)?_c('sidebar-item',{attrs:{"link":{
          name: 'Setup',
          icon: 'ni ni-settings-gear-65 text-aaaa',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'User', path: '/setup/users' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Subsidiaries', path: '/setup/subsidiaries' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Document Type', path: '/setup/document_type' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Application Type', path: '/setup/application_type' }}})],1):_vm._e(),(_vm.loginUser.role_id == 3 && _vm.loginUser.hod == true)?_c('sidebar-item',{attrs:{"link":{
          name: 'Setup',
          icon: 'ni ni-settings-gear-65 text-aaaa',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Document Type', path: '/setup/document_type' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Application Type', path: '/setup/application_type' }}})],1):_vm._e(),(_vm.loginUser.role_id == 1 || _vm.loginUser.role_id == 2 || _vm.loginUser.hod ==true)?_c('sidebar-item',{attrs:{"link":{
          name: 'Group',
          icon: 'ni ni-ungroup text-primary',
          path: '/group/groups'
        }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: 'Documents',
          icon: 'ni ni-single-copy-04 text-red',
          path: '/doc/documents'
        }}})],1)],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }