// export const apiDomain = 'http://localhost:8000/'
// export const LoginURL = apiDomain + 'oauth/token'
// export const homeLink = 'http://127.0.0.1:8082'
// export const fileServer = 'http://localhost:8000/'

// export const apiDomain = 'https://dms_api_demo.fintos.com.my/'
// export const LoginURL = apiDomain + 'oauth/token'
// export const homeLink = 'https://dms_demo.fintos.com.my/'
// export const fileServer = 'https://dms_api_demo.fintos.com.my/'


export const apiDomain = 'https://dms-api.fintos.com.my/'
export const LoginURL = apiDomain + 'oauth/token'
export const homeLink = 'https://dms.fintos.com.my'
export const fileServer = 'https://dms-api.fintos.com.my'

// export const apiDomain = 'http://167.172.86.155:8000/'
// export const LoginURL = apiDomain + 'oauth/token'
// export const homeLink = 'http://167.172.86.155:8080'
// export const fileServer = 'http://167.172.86.155:8000/'

// export const apiDomain = 'http://165.22.240.50:8080/'
// export const LoginURL = apiDomain + 'oauth/token'
// export const homeLink = 'http://165.22.240.50'
// export const fileServer = 'http://165.22.56.35:8080/'

// digital ocean testing server
// export const apiDomain = 'http://165.22.102.146:8000/'
// export const LoginURL = apiDomain + 'oauth/token'
// export const homeLink = 'http://165.22.102.146/'
// export const fileServer = 'http://165.22.102.146:8000/'

// export const apiDomain = 'http://139.59.236.39:8080/'
// export const LoginURL = apiDomain + 'oauth/token'
// export const homeLink = 'http://139.59.236.39/'

export const clientId = '93653827-41fa-4dc4-bc42-7349280e37a7'
export const clientSecret = 'cyLPlmkgjRkjiVjz1z1OITA47id883CHl6QYqZX2'
// export const version = '1.1.22';

export const getHeader = function () {
    const tokenData = JSON.parse(window.localStorage.getItem('authUser'))
    const headers = {
        'Accept' : 'application/json',
        'Authorization' : 'Bearer ' + tokenData.access_token
    }

    return headers
}

export const getcurrentDate = function ()
{
  const current       = new Date()
  const currentMonth  = String((current.getMonth()+1)).padStart(2, '0')
  const currentDay    = String(current.getDate()).padStart(2, '0')
  const currentDate   = current.getFullYear()+'-'+currentMonth+'-'+currentDay

  return currentDate
}

export const getcurrentDateTime = function ()
{
  const current       = new Date()
  const currentMonth  = String((current.getMonth()+1)).padStart(2, '0')
  const currentDay    = String(current.getDate()).padStart(2, '0')
  const currentDateTIme   = current.getFullYear()+'-'+currentMonth+'-'+currentDay+' '+current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();

  return currentDateTIme
}


export const getUserInfo = function () {
    const loginUserData = JSON.parse(window.localStorage.getItem('loginUser'))
    return loginUserData
}
