<template>
  <div>
    <b-navbar toggleable="lg" v-if="$route.name == 'Home'" type="light">
      <div class="container">
        <b-navbar-brand to="/">
          <b-img width="300px" fluid src="/img/logo_fintos.png"></b-img>
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
              <base-button size="L" type="neutral" @click="linkToLogin()">  <i class="fas ni ni-single-02 mr-2"></i>Login</base-button>
          </b-navbar-nav>
        </b-collapse>
      </div>
    </b-navbar>

    <b-navbar toggleable="lg" v-else-if="$route.name == 'Login'" type="dark" class="bg-gradient-success">
      <div class="container">
        <!-- <b-navbar-brand to="/"> -->
            <b-img center width="500px" fluid src="/img/logo_fintos.png"></b-img>
          <!-- </b-navbar-brand> -->
        <!-- <b-navbar-toggle target="nav-collapse"></b-navbar-toggle> -->
        <!-- <b-collapse id="nav-collapse" is-nav> -->
          <!-- Right aligned nav items -->
          <!-- <b-navbar-nav class="ml-auto">
              <base-button size="L" type="neutral" @click="linkToLogin()">  <i class="fas ni ni-single-02 mr-2"></i>Login</base-button>
          </b-navbar-nav>
        </b-collapse> -->
      </div>
    </b-navbar>

    <b-navbar toggleable="lg" v-else type="dark" class="bg-gradient-info">
      <div class="container">
        <b-navbar-brand to="/">
            <b-img width="300px" fluid src="/img/logo_fintos.png"></b-img>
          </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
              <base-button size="L" type="neutral" @click="linkToLogin()">  <i class="fas ni ni-single-02 mr-2"></i>Login</base-button>
          </b-navbar-nav>
        </b-collapse>
      </div>
    </b-navbar>
    
    <div class="main-content">
      <zoom-center-transition
        :duration="pageTransitionDuration"
        mode="out-in"
      >
        <router-view></router-view>
      </zoom-center-transition>
    </div>

    <footer class="py-5" id="footer-main">
      <b-container >
        <b-row align-v="center" class="justify-content-xl-between">
          <b-col xl="6">
            <div class="copyright text-center text-xl-left text-muted">
              © {{year}} <a href="" class="font-weight-bold ml-1" target="_blank">Fintos Venture Group - {{version}}</a>
            </div>
          </b-col>
          <b-col xl="6" class="col-xl-6">
            <!-- <b-nav  class="nav-footer justify-content-center justify-content-xl-end">
              <b-nav-item href="https://www.creative-tim.com" target="_blank" >
                Creative Tim
              </b-nav-item>
              <b-nav-item href="https://www.creative-tim.com/presentation" target="_blank" >
                About Us
              </b-nav-item>
              <b-nav-item href="http://blog.creative-tim.com"  target="_blank">
                Blog
              </b-nav-item>
              <b-nav-item href="https://www.creative-tim.com/license" target="_blank">
                License
              </b-nav-item>
            </b-nav> -->
          </b-col>
        </b-row>
      </b-container>
    </footer>
  </div>
</template>
<script>
  // import { BaseNav } from '@/components';
  // import {version} from '@/assets/js/config.js';
  import { ZoomCenterTransition } from 'vue2-transitions';

  export default {
    components: {
      // BaseNav,
      ZoomCenterTransition
    },
    props: {
      backgroundColor: {
        type: String,
        default: 'black'
      }
    },
    data() {
      return {
        showMenu: false,
        menuTransitionDuration: 250,
        pageTransitionDuration: 200,
        year: new Date().getFullYear(),
        version: process.env.PACKAGE_VERSION || '0',
        pageClass: 'login-page'
      };
    },
    computed: {
      title() {
        return `${this.$route.name} Page`;
      }
    },
    methods: {
      linkToLogin()
      {
         this.$router.push({name: 'Login'})
      },
      toggleNavbar() {
        document.body.classList.toggle('nav-open');
        this.showMenu = !this.showMenu;
      },
      closeMenu() {
        document.body.classList.remove('nav-open');
        this.showMenu = false;
      },
      setBackgroundColor() {
        document.body.classList.add('bg-default');
      },
      removeBackgroundColor() {
        document.body.classList.remove('bg-default');
      },
      updateBackground() {
        if (!this.$route.meta.noBodyBackground) {
          this.setBackgroundColor();
        } else {
          this.removeBackgroundColor()
        }
      }
    },
    beforeDestroy() {
      this.removeBackgroundColor();
    },
    beforeRouteUpdate(to, from, next) {
      // Close the mobile menu first then transition to next page
      if (this.showMenu) {
        this.closeMenu();
        setTimeout(() => {
          next();
        }, this.menuTransitionDuration);
      } else {
        next();
      }
    },
    watch: {
      $route: {
        immediate: true,
        handler: function () {
          this.updateBackground()
        }
      }
    }
  };
</script>
<style lang="scss" scoped>
  $scaleSize: 0.8;
  @keyframes zoomIn8 {
    from {
      opacity: 0;
      transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
    100% {
      opacity: 1;
    }
  }

  .main-content .zoomIn {
    animation-name: zoomIn8;
  }

  @keyframes zoomOut8 {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
      transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
  }

  .main-content .zoomOut {
    animation-name: zoomOut8;
  }

  .navbar.navbar-light {
    background-color: #2dce89 !important;
  }

  // @media only screen and (max-width: 350px) {
  //   .navbar.navbar-dark {
  //     position: absolute;
  //     z-index: 100;
  //   } 
  // }
</style>
